@import "colors";

.generation-choose-page {
  margin-left: 220px;
  width: calc(100% - 220px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 10em;

  p {
    color: $white;
    margin: 0 0 0.5em 0.5em;
    font-family: Roboto-Regular, sans-serif;
    font-size: 1.5rem;
  }

  h3 {

    color: $white;
    margin: 0 0 0.5em 0.5em;
    font-family: Raleway-Bold, sans-serif;
    font-size: 2.5rem;
    font-weight: normal;
    text-align: center;
  }

  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4em;
    padding-top: 4em;

    button {
      padding: 1em 3em;
      color: $mint;
      background-color: transparent;
      border: 2px solid $grayColor;
      border-radius: 5px;
      font-family: Raleway-Medium, sans-serif;
      font-size: 2rem;
      transition: 0.3s;
      cursor: pointer;
      min-width: 250px;
    }
    button:hover {
      border-color: $mint;
      background-color: $mintToast;
    }
  }

}

@media (max-width: 1000px) {
  .generation-choose-page {
    margin: 0;
    width: 100%;

    h3 {
      margin: 0 0 0.5em 0;
    }

    .button-container {
      flex-direction: column;
    }
  }
  
}