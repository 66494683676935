@import "colors";

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 9;
  transition: 0.3s;
}

aside {
  max-width: 200px;
  height: 100%;
  width: 180px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $bg-colorSecond;
  z-index: 10;
  padding: 3em 2em;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8em;
  transition: 0.3s;

  .sideMenu-logoContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10em;

    img {
      cursor: pointer;
    }
  }

  .sideMenu-buttonsContainer {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    .sideMenu-buttonWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1em;
      cursor: pointer;
      padding: 1em 1.5em;
      border-radius: 10px;
      transition: 0.3s;

      .sideMenu-icon {
        height: 1.8rem;
        width: auto;
        color: $textColor;
        transition: 0.3s;
      }

      p {
        color: $textColor;
        padding: 0;
        font-family: Raleway-SemiBold, sans-serif;
        font-size: 1.8rem;
        margin: 0;
        transition: 0.3s;
      }
    }

    .active {
      p {
        color: $mint;
      }

      .sideMenu-icon {
        color: $mint;
      }
    }

    .sideMenu-buttonWrapper:hover {
      background-color: $hoverTextColor;

      .sideMenu-icon {
        color: $mint;
      }
      p {
        color: $mint;
      }
    }
  }

  .sideMenu-logoutWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;
    cursor: pointer;
    padding: 1em 1.5em;
    border-radius: 10px;
    transition: 0.3s;
    margin-bottom: 8em;
    .sideMenu-icon {
      height: 1.8rem;
      width: auto;
      color: $textColor;
      transition: 0.3s;
    }
    p {
      color: $textColor;
      padding: 0;
      font-family: Raleway-SemiBold, sans-serif;
      font-size: 1.8rem;
      margin: 0;
      transition: 0.3s;
    }
  }

  .sideMenu-logoutWrapper:hover {
    background-color: $hoverTextColor;

    .sideMenu-icon {
      color: $red;
    }
    p {
      color: $red;
    }
  }


}
@media screen and (max-width: 1000px) {
  .sideMenu {
    position: fixed;
    top: 0;
    left: -250px;
    height: 100%;
    z-index: 1000;
  }

  .sideMenu.open {
    left: 0;
  }

  .sideMenu.closed {
    left: -250px;
  }

  .sideMenu-closeIcon {
    display: block;
    cursor: pointer;
  }
}