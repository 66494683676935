.generation-entry-page {
  margin-left: 220px;
  width: calc(100% - 220px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10em;
  gap: 3em;
}
.generation-entry-page h3 {
  color: #ffffff;
  margin: 0 0 0.5em 0.5em;
  font-family: Raleway-Bold, sans-serif;
  font-size: 2.5rem;
  font-weight: normal;
}
.generation-entry-page .generation-form {
  display: flex;
  flex-direction: column;
  gap: 3em;
}
.generation-entry-page .generation-form input {
  background-color: transparent;
  border: 2px solid #7b7b7b;
  border-radius: 5px;
  padding: 0.5em;
  color: #ffffff;
  transition: 0.3s;
  font-size: 1.8rem;
  font-family: Roboto-Regular, sans-serif;
  min-width: 350px;
}
.generation-entry-page .generation-form input:focus {
  outline: none;
  border: 2px solid #ffffff;
}
.generation-entry-page .generation-form button {
  padding: 1em 2em;
  border: 2px solid #7b7b7b;
  border-radius: 5px;
  background-color: transparent;
  color: #ffffff;
  transition: 0.3s;
  cursor: pointer;
}
.generation-entry-page .generation-form button:hover {
  background-color: rgba(30, 214, 137, 0.2980392157);
  border-color: #1ED88A;
}

@media (max-width: 1000px) {
  .generation-entry-page {
    margin: 0;
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

