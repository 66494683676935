body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  overscroll-behavior-y: none;
}

/*.layout {*/
/*  height: 100%;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*}*/

html {
  font-size: 62.5%;
  margin: 0;
  padding: 0;
  background-color: #050505;
}
::selection {
  background: #1ED88A;
  color: #F6F6F6;
}

::-moz-selection {
  background-color: #1ED88A;
  color: #F6F6F6;
}

*::-webkit-scrollbar {
  display: none;
}


*::-webkit-scrollbar-thumb {
  display: none;
}

*::-webkit-scrollbar-thumb:hover {
  display: none;
}

@font-face {
  font-family: 'Raleway-Bold';
  src: local(Raleway-Bold), url("../fonts/Raleway-Bold.ttf");
}

@font-face {
  font-family: 'Raleway-SemiBold';
  src: local(Raleway-SemiBold), url("../fonts/Raleway-SemiBold.ttf");
}

@font-face {
  font-family: 'Raleway-Regular';
  src: local(Raleway-Regular), url("../fonts/Raleway-Regular.ttf");
}

@font-face {
  font-family: 'Raleway-Medium';
  src: local(Raleway-Medium), url("../fonts/Raleway-Medium.ttf");
}

@font-face {
  font-family: 'Raleway-Light';
  src: local(Raleway-Light), url("../fonts/Raleway-Light.ttf");
}

@font-face {
  font-family: 'Roboto-Regular';
  src: local(Roboto-Regular), url("../fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: 'Roboto-Light';
  src: local(Roboto-Light), url("../fonts/Roboto-Light.ttf");
}

@font-face {
  font-family: 'Roboto-Medium';
  src: local(Roboto-Medium), url("../fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: 'Roboto-Bold';
  src: local(Roboto-Bold), url("../fonts/Roboto-Bold.ttf");
}
