.publicationDetails-container {
  margin-left: 220px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 6em 4em;
  color: white;
}
.publicationDetails-container .stats-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4em;
  width: 100%;
}
.publicationDetails-container .stats-container .stats-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #1ED88A;
  padding: 1em 4em 1em 1em;
  border-radius: 5px;
  gap: 1em;
  min-width: 140px;
}
.publicationDetails-container .stats-container .stats-wrapper .stats-heading {
  margin: 0;
  font-family: Raleway-Medium, sans-serif;
  font-size: 1.2rem;
}
.publicationDetails-container .stats-container .stats-wrapper .stats-value {
  margin: 0;
  font-family: Roboto-Bold, sans-serif;
  font-size: 2.5rem;
}
.publicationDetails-container .stats-container .stats-wrapper-mint {
  background-color: #215941;
  border: 2px solid #1ED88A;
}
.publicationDetails-container .stats-container .stats-wrapper-standard {
  background-color: #215941;
  border: 2px solid #1ED88A;
}
.publicationDetails-container .stats-container .stats-wrapper-important {
  border: 2px solid #e2ea46;
  background-color: rgba(224, 232, 69, 0.2980392157);
}
.publicationDetails-container .stats-container .stats-wrapper-urgent {
  border: 2px solid #f65606;
  background-color: rgba(244, 85, 6, 0.3);
}
.publicationDetails-container form {
  width: 100%;
  padding-top: 4em;
}
.publicationDetails-container form .form-firstPart-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.publicationDetails-container form .form-firstPart-wrapper .form-firstPart-part {
  width: 50%;
}
.publicationDetails-container form .form-firstPart-wrapper .left {
  padding-left: 1.5em;
  display: flex;
  flex-direction: column;
  gap: 2em;
}
.publicationDetails-container form .form-firstPart-wrapper .right {
  padding-right: 1.5em;
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.form-input-wrapper {
  display: flex;
  flex-direction: column;
}
.form-input-wrapper label {
  color: #ffffff;
  font-size: 1.4rem;
  font-family: Raleway-Regular, sans-serif;
  padding: 0 0 0.3em 0.2em;
}
.form-input-wrapper input {
  background-color: transparent;
  border: 2px solid #7b7b7b;
  border-radius: 5px;
  padding: 0.5em;
  color: #ffffff;
  transition: 0.3s;
  font-size: 1.8rem;
  font-family: Roboto-Regular, sans-serif;
}
.form-input-wrapper input:focus {
  outline: none;
  border: 2px solid #ffffff;
}

.fieldset-container {
  display: flex;
  flex-direction: column;
}
.fieldset-container fieldset {
  border: 2px solid #7b7b7b;
  border-radius: 5px;
  margin: 0;
  display: flex;
  flex-direction: column;
  padding: 2em 4em 2em 1em;
  gap: 2.45em;
}
.fieldset-container fieldset label {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  cursor: pointer;
}
.fieldset-container fieldset label .category-text {
  font-family: Roboto-Regular, sans-serif;
  font-size: 1.8rem;
  transition: 0.3s;
}
.fieldset-container fieldset label input[type=radio] {
  display: none;
  accent-color: #1ED88A;
  transition: 0.3s;
  margin: 0;
  width: 30px;
}
.fieldset-container fieldset label .radio-mark {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  background-color: #7b7b7b;
  position: relative;
  cursor: pointer;
  transition: 0.3s;
}
.fieldset-container fieldset label input[type=radio]:checked + .business {
  background-color: rgba(81, 32, 255, 0.2980392157);
  border-color: #5120ff;
}
.fieldset-container fieldset label input[type=radio]:checked + .sport {
  background-color: rgba(16, 187, 223, 0.3);
  border-color: #07C3FF;
}
.fieldset-container fieldset label input[type=radio]:checked + .politics {
  background-color: rgba(255, 156, 0, 0.2980392157);
  border-color: #F29220;
}
.fieldset-container fieldset label input[type=radio]:checked + .news {
  background-color: rgba(217, 0, 66, 0.2980392157);
  border-color: #E82A4D;
}
.fieldset-container fieldset label input[type=radio]:checked + .mint {
  background-color: rgba(30, 214, 137, 0.2980392157);
  border-color: #1ED88A;
}
.fieldset-container fieldset label input[type=radio]:checked + .radio-mark.news + .category-text {
  color: #E82A4D;
}
.fieldset-container fieldset label input[type=radio]:checked + .radio-mark.politics + .category-text {
  color: #F29220;
}
.fieldset-container fieldset label input[type=radio]:checked + .radio-mark.sport + .category-text {
  color: #07C3FF;
}
.fieldset-container fieldset label input[type=radio]:checked + .radio-mark.business + .category-text {
  color: #5120ff;
}
.fieldset-container fieldset label input[type=radio]:checked + .radio-mark.mint + .category-text {
  color: #1ED88A;
}

.fieldset-label {
  color: #ffffff;
  font-size: 1.4rem;
  font-family: Raleway-Regular, sans-serif;
  padding: 0 0 0.3em 0.2em;
}

.summary-container {
  padding-top: 2em;
}
.summary-container textarea {
  min-height: 230px;
  background-color: transparent;
  border: 2px solid #7b7b7b;
  border-radius: 5px;
  color: white;
  font-family: Roboto-Regular, sans-serif;
  font-size: 1.8rem;
  line-height: 3.5rem;
  padding: 0.5em;
}

.form-button-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 3em;
  gap: 3em;
}
.form-button-container button {
  padding: 1em 2em;
  border: 2px solid #7b7b7b;
  border-radius: 5px;
  background-color: transparent;
  color: #ffffff;
  transition: 0.3s;
  cursor: pointer;
}
.form-button-container button:hover {
  background-color: rgba(30, 214, 137, 0.2980392157);
  border-color: #1ED88A;
}
.form-button-container .regenerate:hover {
  border-color: #e2ea46;
  background-color: rgba(224, 232, 69, 0.2980392157);
}
.form-button-container .update:hover {
  border-color: #07C3FF;
  background-color: rgba(16, 187, 223, 0.3);
}

.delete-button-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 3em;
}
.delete-button-container button {
  padding: 1em 2em;
  border: 2px solid #E82A4D;
  border-radius: 5px;
  background-color: transparent;
  color: #ffffff;
  transition: 0.3s;
  cursor: pointer;
}
.delete-button-container button:hover {
  background-color: rgba(217, 0, 66, 0.2980392157);
}

.schedule-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 3em;
}
.schedule-container label {
  color: #ffffff;
  font-size: 1.4rem;
  font-family: Raleway-Regular, sans-serif;
  padding: 0 0 0.3em 0.2em;
}
.schedule-container .schedule-wrapper {
  display: flex;
  gap: 2em;
  justify-content: center;
  align-items: center;
}
.schedule-container input {
  background-color: transparent;
  border: 2px solid #7b7b7b;
  border-radius: 5px;
  padding: 0.5em;
  color: #ffffff;
  transition: 0.3s;
  font-size: 1.8rem;
  font-family: Roboto-Regular, sans-serif;
}
.schedule-container input:focus {
  outline: none;
  border: 2px solid #ffffff;
}
.schedule-container button {
  padding: 1em 2em;
  border: 2px solid #7b7b7b;
  border-radius: 5px;
  background-color: transparent;
  color: #ffffff;
  transition: 0.3s;
  cursor: pointer;
}
.schedule-container button:hover {
  background-color: #27195b;
  border-color: #5120ff;
}

@media (max-width: 1000px) {
  .publicationDetails-container {
    margin: 0;
    padding: 4em 1.5em 8em 1.5em;
  }
  .publicationDetails-container .stats-container {
    gap: 0;
    justify-content: space-between;
  }
  .publicationDetails-container .stats-container .stats-wrapper {
    padding: 1em 2em 1em 1em;
  }
  .publicationDetails-container .stats-container .stats-wrapper .stats-value {
    font-size: 1.8rem;
  }
  .publicationDetails-container form {
    padding-top: 3em;
  }
  .publicationDetails-container form .form-firstPart-wrapper {
    flex-direction: column;
    gap: 3em;
  }
  .publicationDetails-container form .form-firstPart-wrapper .form-firstPart-part {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .form-input-wrapper input {
    font-size: 1.5rem;
  }

  .fieldset-container fieldset {
    padding: 2em;
  }

  .summary-container textarea {
    font-size: 1.5rem;
    line-height: 3rem;
    min-height: 400px;
  }

  .form-button-container {
    flex-direction: column-reverse;
  }
  .form-button-container button {
    width: 100%;
  }

  .delete-button-container {
    display: flex;
    width: 100%;
  }
  .delete-button-container button {
    width: 100%;
  }
}

