.dashboard-loader-wrapper {
  margin-left: 220px;
  width: calc(100% - 220px);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding-top: 20em;
}

.dashboard-container {
  margin-left: 220px;
  padding: 4em 4em;
  display: flex;
  flex-direction: column;
  gap: 6em;
}
.dashboard-container h3 {
  color: #ffffff;
  margin: 0 0 0.5em 0.5em;
  font-family: Raleway-Bold, sans-serif;
  font-size: 2.5rem;
  font-weight: normal;
}
.dashboard-container .category-news {
  color: #E82A4D;
  background-color: rgba(217, 0, 66, 0.2980392157);
}
.dashboard-container .category-sport {
  color: #07C3FF;
  background-color: rgba(16, 187, 223, 0.3);
}
.dashboard-container .category-politics {
  color: #F29220;
  background-color: rgba(255, 156, 0, 0.2980392157);
}
.dashboard-container .category-businessandfinance {
  color: #5120ff;
  background-color: rgba(81, 32, 255, 0.2980392157);
}
.dashboard-container .importance-standard {
  color: #1ED88A;
  background-color: rgba(30, 214, 137, 0.2980392157);
}
.dashboard-container .importance-important {
  color: #e2ea46;
  background-color: rgba(224, 232, 69, 0.3);
}
.dashboard-container .importance-urgent {
  color: #f65606;
  background-color: rgba(244, 85, 6, 0.3);
}
.dashboard-container .category, .dashboard-container .importance {
  padding: 0.4em 1em;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  font-size: 1.4rem;
  font-family: Roboto-Regular, sans-serif;
}
.dashboard-container ul {
  background-color: #161617;
  padding: 2em;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.dashboard-container ul .separator {
  height: 2px;
  width: 100%;
  background-color: #7b7b7b;
  margin: 1.5em 0;
}
.dashboard-container ul .extra-separator {
  height: 2px;
  width: 100%;
  background-color: #7b7b7b;
  margin: 0 0 1.5em 0;
}
.dashboard-container ul .separator-small {
  width: 2px;
  height: 20px;
  background-color: #7b7b7b;
}
.dashboard-container ul li:last-of-type + .separator {
  margin-bottom: 0;
}
.dashboard-container ul li {
  color: #ffffff;
  list-style: none;
  font-size: 2rem;
  display: flex;
  justify-content: space-between;
  padding: 0.5em 1em;
  border-radius: 5px;
  transition: 0.3s;
}
.dashboard-container ul li .text-flags-wrapper {
  display: flex;
  gap: 1em;
  justify-content: center;
  align-items: center;
}
.dashboard-container ul li .text-flags-wrapper h4 {
  margin: 0;
  font-weight: normal;
  font-family: Roboto-Medium, sans-serif;
}
.dashboard-container ul li .text-flags-wrapper .flag-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3em;
}
.dashboard-container ul li .text-flags-wrapper .flag-container span {
  display: flex;
  align-items: center;
}
.dashboard-container ul li .dashboard-icons-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
}
.dashboard-container ul li .dashboard-icons-wrapper .dashboard-icon {
  height: 2.5rem;
  width: auto;
  color: #ffffff;
  transition: 0.3s;
}
.dashboard-container ul li .dashboard-icons-wrapper .pencilIcon {
  height: 2.1rem;
}
.dashboard-container ul li .dashboard-icons-wrapper .copyIcon:hover {
  color: #1ED88A;
}
.dashboard-container ul li .dashboard-icons-wrapper .trashIcon:hover {
  color: #E82A4D;
}
.dashboard-container ul li .dashboard-icons-wrapper .pencilIcon:hover {
  color: #FF9C00;
}
.dashboard-container ul .li-brak {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-container ul .li-brak .regenerated-brak {
  color: #e2ea46;
}
.dashboard-container ul .li-brak .unpublished-brak {
  color: #ffffff;
}
.dashboard-container ul .li-brak p {
  font-size: 3rem;
  margin: 0;
}
.dashboard-container ul .li-regeneration {
  color: #e2ea46;
}
.dashboard-container ul li:hover {
  background-color: rgb(35, 35, 35);
  cursor: pointer;
}

@media (max-width: 1000px) {
  .dashboard-loader-wrapper {
    margin: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .dashboard-container {
    margin: 0;
    padding: 4em 1.5em;
  }
  .dashboard-container h3 {
    font-size: 2rem;
    margin-left: 0.3em;
  }
  .dashboard-container ul {
    padding: 1em;
  }
  .dashboard-container ul .separator {
    margin: 0.2em 0;
  }
  .dashboard-container ul .extra-separator {
    margin-bottom: 0.2em;
  }
  .dashboard-container ul li {
    padding: 0.5em;
    gap: 1.5em;
  }
  .dashboard-container ul li .text-flags-wrapper {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 0.5em;
  }
  .dashboard-container ul li h4 {
    font-size: 1.6rem;
  }
  .dashboard-container ul li .dashboard-icons-wrapper {
    flex-direction: column;
  }
  .dashboard-container ul li .dashboard-icons-wrapper .separator-small {
    display: none;
  }
  .dashboard-container ul li .dashboard-icons-wrapper .dashboard-icon {
    height: 2.8rem;
    padding: 0.2em;
  }
  .dashboard-container ul .li-brak p {
    font-size: 2rem;
    margin: 0;
  }
}

