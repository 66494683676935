.storyForm-container {
  margin-left: 220px;
  padding: 4em 4em;
  display: flex;
  flex-direction: column;
  gap: 6em;
}
.storyForm-container form {
  color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2em;
}
.storyForm-container form .form-element {
  display: flex;
  flex-direction: column;
}
.storyForm-container form .form-element label {
  color: #ffffff;
  font-size: 1.4rem;
  font-family: Raleway-Regular, sans-serif;
  padding: 0 0 0.3em 0.2em;
}
.storyForm-container form .form-element input {
  background-color: transparent;
  border: 2px solid #7b7b7b;
  border-radius: 5px;
  padding: 0.5em;
  color: #ffffff;
  transition: 0.3s;
  font-size: 1.8rem;
  font-family: Roboto-Regular, sans-serif;
}
.storyForm-container form .form-element input:focus {
  outline: none;
  border: 2px solid #ffffff;
}
.storyForm-container form .form-element textarea {
  min-height: 70px;
  background-color: transparent;
  border: 2px solid #7b7b7b;
  border-radius: 5px;
  color: white;
  font-family: Roboto-Regular, sans-serif;
  font-size: 1.8rem;
  line-height: 3.5rem;
  padding: 0.5em;
  transition: 0.3s;
}
.storyForm-container form .form-element textarea:focus {
  outline: none;
  border: 2px solid #ffffff;
}
.storyForm-container form .form-checkboxes {
  display: flex;
  flex-direction: column;
  gap: 2em;
}
.storyForm-container form .form-checkboxes .checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: 2em;
}
.storyForm-container form .form-checkboxes .checkbox-wrapper label {
  margin-right: 10px;
  font-size: 2rem;
  font-family: Raleway-Regular, sans-serif;
}
.storyForm-container form .form-checkboxes .checkbox-wrapper input[type=checkbox] {
  width: 30px;
  height: 30px;
  cursor: pointer;
  appearance: none;
  border: 2px solid #7b7b7b;
  border-radius: 4px;
  background-color: transparent;
  margin: 0;
}
.storyForm-container form .form-checkboxes .checkbox-wrapper input[type=checkbox]:checked {
  background-color: #184e39;
  border-color: #1ED88A;
}
.storyForm-container form .form-checkboxes .checkbox-wrapper input[type=checkbox]:hover {
  border-color: #1ED88A;
}
.storyForm-container form .form-checkboxes .checkbox-wrapper input[type=checkbox]:focus {
  outline: none;
  box-shadow: none;
}
.storyForm-container form .swiper-container img {
  width: 100%;
  height: 230px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 5px;
  box-sizing: border-box;
  opacity: 0.8;
}
.storyForm-container form .swiper-container .active {
  border: 3px solid #1ED88A;
  opacity: 1;
}
.storyForm-container form .swiper-container .source-name {
  font-size: 2rem;
  font-family: Raleway-Regular, sans-serif;
  text-align: center;
}
.storyForm-container form .button-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.storyForm-container form .button-container button {
  padding: 1em 2em;
  border: 2px solid #7b7b7b;
  border-radius: 5px;
  background-color: transparent;
  color: #ffffff;
  transition: 0.3s;
  cursor: pointer;
  min-width: 250px;
}
.storyForm-container form .button-container button:hover {
  background-color: #27195b;
  border-color: #5120ff;
}
.storyForm-container form .post-button-container {
  gap: 3em;
}
.storyForm-container form .post-button-container button {
  min-width: 250px;
}
.storyForm-container .publication-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2em;
}
.storyForm-container .publication-details .form-element {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.storyForm-container .publication-details .form-element label {
  color: #ffffff;
  font-size: 1.4rem;
  font-family: Raleway-Regular, sans-serif;
  padding: 0 0 0.3em 0.2em;
}
.storyForm-container .publication-details .form-element input {
  background-color: transparent;
  border: 2px solid #5120ff;
  border-radius: 5px;
  padding: 0.5em;
  color: #ffffff;
  transition: 0.3s;
  font-size: 1.8rem;
  font-family: Roboto-Regular, sans-serif;
}
.storyForm-container .publication-details .form-element input:focus {
  outline: none;
}
.storyForm-container .publication-details .form-element textarea {
  min-height: 230px;
  background-color: transparent;
  border: 2px solid #5120ff;
  border-radius: 5px;
  color: white;
  font-family: Roboto-Regular, sans-serif;
  font-size: 1.8rem;
  line-height: 3.5rem;
  padding: 0.5em;
  transition: 0.3s;
}
.storyForm-container .publication-details .form-element textarea:focus {
  outline: none;
}

@media (max-width: 1000px) {
  .storyForm-container {
    margin: 0;
    width: calc(100% - 3em);
    padding: 4em 1.5em 8em 1.5em;
  }
  .storyForm-container form .form-element input {
    font-size: 1.5rem;
  }
  .storyForm-container form .form-element textarea {
    font-size: 1.5rem;
    height: 150px;
  }
  .storyForm-container form .swiper-container img {
    width: 100%;
    height: 230px;
  }
  .storyForm-container form .button-container button {
    width: 100%;
  }
  .storyForm-container form .post-button-container {
    gap: 3em;
    flex-direction: column;
  }
  .storyForm-container .publication-details .form-element input {
    font-size: 1.5rem;
  }
  .storyForm-container .publication-details .form-element textarea {
    font-size: 1.5rem;
  }
}

