.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 9;
  transition: 0.3s;
}

aside {
  max-width: 200px;
  height: 100%;
  width: 180px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #161617;
  z-index: 10;
  padding: 3em 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8em;
  transition: 0.3s;
}
aside .sideMenu-logoContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10em;
}
aside .sideMenu-logoContainer img {
  cursor: pointer;
}
aside .sideMenu-buttonsContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}
aside .sideMenu-buttonsContainer .sideMenu-buttonWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
  cursor: pointer;
  padding: 1em 1.5em;
  border-radius: 10px;
  transition: 0.3s;
}
aside .sideMenu-buttonsContainer .sideMenu-buttonWrapper .sideMenu-icon {
  height: 1.8rem;
  width: auto;
  color: #d9e2e6;
  transition: 0.3s;
}
aside .sideMenu-buttonsContainer .sideMenu-buttonWrapper p {
  color: #d9e2e6;
  padding: 0;
  font-family: Raleway-SemiBold, sans-serif;
  font-size: 1.8rem;
  margin: 0;
  transition: 0.3s;
}
aside .sideMenu-buttonsContainer .active p {
  color: #1ED88A;
}
aside .sideMenu-buttonsContainer .active .sideMenu-icon {
  color: #1ED88A;
}
aside .sideMenu-buttonsContainer .sideMenu-buttonWrapper:hover {
  background-color: rgb(35, 35, 35);
}
aside .sideMenu-buttonsContainer .sideMenu-buttonWrapper:hover .sideMenu-icon {
  color: #1ED88A;
}
aside .sideMenu-buttonsContainer .sideMenu-buttonWrapper:hover p {
  color: #1ED88A;
}
aside .sideMenu-logoutWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
  cursor: pointer;
  padding: 1em 1.5em;
  border-radius: 10px;
  transition: 0.3s;
  margin-bottom: 8em;
}
aside .sideMenu-logoutWrapper .sideMenu-icon {
  height: 1.8rem;
  width: auto;
  color: #d9e2e6;
  transition: 0.3s;
}
aside .sideMenu-logoutWrapper p {
  color: #d9e2e6;
  padding: 0;
  font-family: Raleway-SemiBold, sans-serif;
  font-size: 1.8rem;
  margin: 0;
  transition: 0.3s;
}
aside .sideMenu-logoutWrapper:hover {
  background-color: rgb(35, 35, 35);
}
aside .sideMenu-logoutWrapper:hover .sideMenu-icon {
  color: #E82A4D;
}
aside .sideMenu-logoutWrapper:hover p {
  color: #E82A4D;
}

@media screen and (max-width: 1000px) {
  .sideMenu {
    position: fixed;
    top: 0;
    left: -250px;
    height: 100%;
    z-index: 1000;
  }

  .sideMenu.open {
    left: 0;
  }

  .sideMenu.closed {
    left: -250px;
  }

  .sideMenu-closeIcon {
    display: block;
    cursor: pointer;
  }
}

