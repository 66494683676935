.toast-update {
  background-color: #144652 !important;
  color: #ffffff !important;
  border: 2px solid #07C3FF;
  font-size: 1.5rem;
  font-family: Roboto-Regular, sans-serif !important;
  opacity: 1 !important;
}

.toast-regenerate {
  background-color: #525425 !important;
  color: #ffffff !important;
  border: 2px solid #e2ea46;
  font-size: 1.5rem;
  font-family: Roboto-Regular, sans-serif !important;
  opacity: 1 !important;
}

.toast-delete {
  background-color: #4f0f24 !important;
  color: #ffffff !important;
  border: 2px solid #E82A4D;
  font-size: 1.5rem;
  font-family: Roboto-Regular, sans-serif !important;
  opacity: 1 !important;
}

.toast-publish {
  background-color: #184e39 !important;
  color: #ffffff !important;
  border: 2px solid #1ED88A;
  font-size: 1.5rem;
  font-family: Roboto-Regular, sans-serif !important;
  opacity: 1 !important;
}

.toast-schedule {
  background-color: #27195b !important;
  color: #ffffff !important;
  border: 2px solid #5120ff;
  font-size: 1.5rem;
  font-family: Roboto-Regular, sans-serif !important;
  opacity: 1 !important;
}

.toast-error {
  background-color: #4f0f24 !important;
  color: #E82A4D !important;
  border: 2px solid #E82A4D;
  font-size: 1.5rem;
  font-family: Roboto-Regular, sans-serif !important;
  opacity: 1 !important;
}

.toast-pending {
  background-color: #111111 !important;
  color: #ffffff !important;
  border: 2px solid #ffffff;
  font-size: 1.5rem;
  font-family: Roboto-Regular, sans-serif !important;
  opacity: 1 !important;
}

