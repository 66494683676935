.generation-choose-page {
  margin-left: 220px;
  width: calc(100% - 220px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 10em;
}
.generation-choose-page p {
  color: #ffffff;
  margin: 0 0 0.5em 0.5em;
  font-family: Roboto-Regular, sans-serif;
  font-size: 1.5rem;
}
.generation-choose-page h3 {
  color: #ffffff;
  margin: 0 0 0.5em 0.5em;
  font-family: Raleway-Bold, sans-serif;
  font-size: 2.5rem;
  font-weight: normal;
  text-align: center;
}
.generation-choose-page .button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4em;
  padding-top: 4em;
}
.generation-choose-page .button-container button {
  padding: 1em 3em;
  color: #1ED88A;
  background-color: transparent;
  border: 2px solid #7b7b7b;
  border-radius: 5px;
  font-family: Raleway-Medium, sans-serif;
  font-size: 2rem;
  transition: 0.3s;
  cursor: pointer;
  min-width: 250px;
}
.generation-choose-page .button-container button:hover {
  border-color: #1ED88A;
  background-color: #184e39;
}

@media (max-width: 1000px) {
  .generation-choose-page {
    margin: 0;
    width: 100%;
  }
  .generation-choose-page h3 {
    margin: 0 0 0.5em 0;
  }
  .generation-choose-page .button-container {
    flex-direction: column;
  }
}

