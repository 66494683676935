main {
  background: linear-gradient(164deg, rgb(80, 163, 162) 0%, rgb(30, 216, 138) 100%), linear-gradient(rgba(255, 255, 255, 0.2) 1px, transparent 1px), linear-gradient(to right, rgba(255, 255, 255, 0.2) 1px, transparent 1px);
  background-size: 100% 100%, 80px 80px, 80px 80px;
  background-blend-mode: overlay;
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}
main .login-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  background-color: white;
  padding: 5em 5em;
  min-width: 280px;
  min-height: 200px;
}
main .login-container img {
  min-height: 60px;
  padding-bottom: 5em;
}
main .login-container form {
  display: flex;
  flex-direction: column;
  gap: 7em;
}
main .login-container form .login-container-inputs {
  display: flex;
  flex-direction: column;
  gap: 2em;
}
main .login-container form .login-container-inputs label {
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  font-family: Raleway-Light, sans-serif;
}
main .login-container form .login-container-inputs input {
  margin-top: 0.3em;
  font-size: 2.5rem;
  padding: 0.2em 1em 0.2em 0.4em;
  border-radius: 5px;
  border: 2px solid rgba(17, 17, 17, 0.6);
  font-family: Raleway-Regular, sans-serif;
  transition: 0.3s;
}
main .login-container form .login-container-inputs input:focus {
  outline: #1ED88A;
  border: 2px solid #1ED88A;
}
main .login-container form .login-container-inputs p {
  color: #E82A4D;
  margin: 0;
  text-align: center;
  font-size: 1.2rem;
  font-family: Raleway-Regular, sans-serif;
}
main .login-container form .login-container-buttonWrapper {
  min-height: 60px;
  display: flex;
  flex-direction: column;
}
main .login-container form .login-container-buttonWrapper button {
  background: linear-gradient(164deg, rgb(80, 163, 162) 0%, rgb(30, 216, 138) 100%);
  border: none;
  padding: 0.4em;
  font-size: 2rem;
  color: #ffffff;
  border-radius: 5px;
  font-family: Raleway-Bold, sans-serif;
  cursor: pointer;
  transition: opacity 0.3s;
  width: 100%;
}
main .login-container form .login-container-buttonWrapper button:hover {
  opacity: 0.7;
}
main .login-container form .login-container-buttonWrapper p {
  margin: 0;
}
main .login-container .googleLoginButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
  border: 1px solid #1ED88A;
  width: 100%;
  border-radius: 0.5rem;
  height: 38px;
}
main .login-container .googleLoginButton a {
  color: #111111;
  font-size: 2rem;
  text-decoration: none;
  font-family: Raleway-Regular, sans-serif;
}
main .login-container .googleLoginButton img {
  width: 23px;
  height: 23px !important;
  margin: 0;
  padding: 0;
  min-height: 0;
}
main .login-note {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
main .login-note h4 {
  margin: 0;
  font-weight: normal;
  font-family: Raleway-SemiBold, sans-serif;
  font-size: 1.4rem;
}
main .login-note p {
  font-family: Raleway-Medium, sans-serif;
  font-size: 1.2rem;
}
main .login-note h4, main .login-note p {
  color: white;
}

@media (max-width: 600px) {
  main {
    height: 100%;
  }
  main .login-container {
    padding: 2em 2em;
    min-width: 280px;
  }
  main .login-container img {
    min-height: 45px;
  }
  main .login-container form {
    max-width: 270px;
  }
  main .login-container form .login-container-inputs {
    display: flex;
    flex-direction: column;
    gap: 2em;
  }
  main .login-container form .login-container-inputs label {
    font-size: 1.2rem;
  }
  main .login-container form .login-container-inputs input {
    font-size: 2rem;
  }
}

