@import "colors";

.storyForm-container {
  margin-left: 220px;
  padding: 4em 4em;
  display: flex;
  flex-direction: column;
  gap: 6em;


  form {
    color: $white;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2em;

    .form-element {
     display: flex;
      flex-direction: column;

      label {
        color: $white;
        font-size: 1.4rem;
        font-family: Raleway-Regular, sans-serif;
        padding: 0 0 0.3em 0.2em;
      }

      input {
        background-color: transparent;
        border: 2px solid $grayColor;
        border-radius: 5px;
        padding: 0.5em;
        color: $white;
        transition: 0.3s;
        font-size: 1.8rem;
        font-family: Roboto-Regular, sans-serif;
      }

      input:focus {
        outline: none;
        border: 2px solid $white;
      }

      textarea {
        min-height: 70px;
        background-color: transparent;
        border: 2px solid $grayColor;
        border-radius: 5px;
        color: white;
        font-family: Roboto-Regular, sans-serif;
        font-size: 1.8rem;
        line-height: 3.5rem;
        padding: 0.5em;
        transition: 0.3s;
      }
      textarea:focus {

        outline: none;
        border: 2px solid $white;
      }
    }

    .form-checkboxes {
      display: flex;
      flex-direction: column;
      gap: 2em;
      .checkbox-wrapper {
        display: flex;
        align-items: center;
        gap: 2em;

        label {
          margin-right: 10px;
          font-size: 2rem;
          font-family: Raleway-Regular, sans-serif;
        }

        input[type="checkbox"] {
          width: 30px;
          height: 30px;
          cursor: pointer;
          appearance: none;
          border: 2px solid $grayColor;
          border-radius: 4px;
          background-color: transparent;
          margin: 0;

          &:checked {
            background-color: $mintToast;
            border-color: $mint;
          }

          &:hover {
            border-color: $mint;
          }

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }
    }


    .swiper-container {
      img {
        width: 100%;
        height: 230px;
        object-fit: cover;
        cursor: pointer;
        border-radius: 5px;
        box-sizing: border-box ;
        opacity: 0.8;
      }

      .active {
        border: 3px solid $mint;
        opacity: 1;
      }

      .source-name {
        font-size: 2rem;
        font-family: Raleway-Regular, sans-serif;
        text-align: center;
      }

    }

    .button-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;


      button {
        padding: 1em 2em;
        border: 2px solid $grayColor;
        border-radius: 5px;
        background-color: transparent;
        color: $white;
        transition: 0.3s;
        cursor: pointer;
        min-width: 250px;
      }

      button:hover {
        background-color: $purpleToast;
        border-color: $purple;
      }

    }
    .post-button-container {
      gap: 3em;
      button {
        min-width: 250px;
      }
    }
  }

  .publication-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2em;
    .form-element {
      display: flex;
      width: 100%;
      flex-direction: column;

      label {
        color: $white;
        font-size: 1.4rem;
        font-family: Raleway-Regular, sans-serif;
        padding: 0 0 0.3em 0.2em;
      }

      input {
        background-color: transparent;
        border: 2px solid $purple;
        border-radius: 5px;
        padding: 0.5em;
        color: $white;
        transition: 0.3s;
        font-size: 1.8rem;
        font-family: Roboto-Regular, sans-serif;
      }

      input:focus {
        outline: none;
      }

      textarea {
        min-height: 230px;
        background-color: transparent;
        border: 2px solid $purple;
        border-radius: 5px;
        color: white;
        font-family: Roboto-Regular, sans-serif;
        font-size: 1.8rem;
        line-height: 3.5rem;
        padding: 0.5em;
        transition: 0.3s;
      }

      textarea:focus {
        outline: none;
      }

    }
  }
}

@media (max-width: 1000px) {
  .storyForm-container {
    margin: 0;
    width: calc(100% - 3em);
    padding: 4em 1.5em 8em 1.5em;

    form {
      .form-element {

        input {
          font-size: 1.5rem;
        }

        textarea {
          font-size: 1.5rem;
          height: 150px;
        }
      }

      .swiper-container {
        img {
          width: 100%;
          height: 230px;
        }
      }

      .button-container {
        button {
          width: 100%;
        }
      }

      .post-button-container {
        gap: 3em;
        flex-direction: column;
        button {
        }
      }


    }

    .publication-details {
      .form-element {
        input {
          font-size: 1.5rem;
        }

        textarea {
          font-size: 1.5rem;
        };
      }
    }
  }
}