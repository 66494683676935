nav {
  padding: 2em 4em;
  z-index: 2;
  position: sticky;
  margin-left: 220px;
}
nav .navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
nav .navbar-container .hamburger {
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1em;
}
nav .navbar-container .hamburger .hamburger-bar {
  width: 40px;
  height: 2px;
  background-color: #1ED88A;
}
nav .navbar-container h2 {
  font-weight: normal;
  color: #ffffff;
  font-family: Raleway-Regular, sans-serif;
}
nav .navbar-container h2 span {
  color: #1ED88A;
}
nav .navbar-container .navbar-search {
  border-bottom: 2px solid #7b7b7b;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}
nav .navbar-container .navbar-search input {
  border: none;
  background-color: transparent;
  transition: 0.3s;
  color: #7b7b7b;
  font-size: 1.8rem;
  padding: 0.4em;
}
nav .navbar-container .navbar-search input:focus {
  outline: none;
  color: #ffffff;
}
nav .navbar-container .navbar-search:focus-within {
  border-color: #ffffff;
}
nav .navbar-container .navbar-search .navbar-icons-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
  padding-bottom: 0.5em;
}
nav .navbar-container .navbar-search .navbar-icons-wrapper .search-icon {
  height: 2.5rem;
  width: auto;
  color: #ffffff;
  cursor: pointer;
  padding-right: 1em;
}
nav .navbar-container .navbar-search .navbar-icons-wrapper .pencil-icon {
  width: auto;
  color: #ffffff;
  cursor: pointer;
  height: 2rem;
}
nav .navbar-container .navbar-search .navbar-icons-wrapper .separator {
  width: 1px;
  height: 2rem;
  background-color: #ffffff;
}

@media (max-width: 1000px) {
  nav {
    margin: 0;
    padding: 1em 1.5em;
  }
  nav .navbar-container h2 {
    display: none;
  }
  nav .navbar-container .hamburger {
    display: flex;
  }
}

