@import 'colors';


.toast-update {
  background-color: $blueToast !important;
  color: $white !important;
  border: 2px solid $blue;
  font-size: 1.5rem;
  font-family: Roboto-Regular, sans-serif !important;
  opacity: 1 !important;
}

.toast-regenerate {
  background-color: $yellowToast !important;
  color: $white !important;
  border: 2px solid $yellow;
  font-size: 1.5rem;
  font-family: Roboto-Regular, sans-serif !important;
  opacity: 1 !important;
}

.toast-delete {
  background-color: $redToast !important;
  color: $white !important;
  border: 2px solid $red;
  font-size: 1.5rem;
  font-family: Roboto-Regular, sans-serif !important;
  opacity: 1 !important;
}

.toast-publish {
  background-color: $mintToast !important;
  color: $white !important;
  border: 2px solid $mint;
  font-size: 1.5rem;
  font-family: Roboto-Regular, sans-serif !important;
  opacity: 1 !important;
}

.toast-schedule {
  background-color: $purpleToast !important;
  color: $white !important;
  border: 2px solid $purple;
  font-size: 1.5rem;
  font-family: Roboto-Regular, sans-serif !important;
  opacity: 1 !important;
}

.toast-error {
  background-color: $redToast !important;
  color: $red !important;
  border: 2px solid $red;
  font-size: 1.5rem;
  font-family: Roboto-Regular, sans-serif !important;
  opacity: 1 !important;
}

.toast-pending {
  background-color: #111111 !important;
  color: #ffffff !important;
  border: 2px solid #ffffff;
  font-size: 1.5rem;
  font-family: Roboto-Regular, sans-serif !important;
  opacity: 1 !important;
}
