.notifications-page {
  margin-left: 220px;
  width: calc(100% - 220px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10em;
  gap: 3em;
}
.notifications-page h3 {
  color: #ffffff;
  margin: 0 0 0.5em 0.5em;
  font-family: Raleway-Bold, sans-serif;
  font-size: 2.5rem;
  font-weight: normal;
  margin: 0;
}
.notifications-page .notification-form {
  display: flex;
  flex-direction: column;
  gap: 3em;
}
.notifications-page .notification-form input {
  background-color: transparent;
  border: 2px solid #7b7b7b;
  border-radius: 5px;
  padding: 0.5em;
  color: #ffffff;
  transition: 0.3s;
  font-size: 1.8rem;
  font-family: Roboto-Regular, sans-serif;
  min-width: 350px;
}
.notifications-page .notification-form input:focus {
  outline: none;
  border: 2px solid #ffffff;
}
.notifications-page .notification-form button {
  padding: 1em 2em;
  border: 2px solid #7b7b7b;
  border-radius: 5px;
  background-color: transparent;
  color: #ffffff;
  transition: 0.3s;
  cursor: pointer;
}
.notifications-page .notification-form button:hover {
  background-color: rgba(30, 214, 137, 0.2980392157);
  border-color: #1ED88A;
}

.notifications-error-page {
  margin-left: 220px;
  width: calc(100% - 220px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 25em;
}
.notifications-error-page .permission-text {
  font-size: 3rem;
  color: #E82A4D;
  font-family: Raleway-SemiBold, sans-serif;
  margin: 0;
}

@media (max-width: 1000px) {
  .notifications-page {
    margin: 0;
    width: 100%;
  }

  .notifications-error-page {
    margin: 0;
    width: 100%;
  }
}

