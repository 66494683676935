.regenerated-details-container {
  margin-left: 220px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 6em 4em;
  color: white;
  gap: 3em;
}
.regenerated-details-container .form-element {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.regenerated-details-container .form-element label {
  color: #ffffff;
  font-size: 1.4rem;
  font-family: Raleway-Regular, sans-serif;
  padding: 0 0 0.3em 0.2em;
}
.regenerated-details-container .form-element input {
  background-color: transparent;
  border: 2px solid #7b7b7b;
  border-radius: 5px;
  padding: 0.5em;
  color: #ffffff;
  transition: 0.3s;
  font-size: 1.8rem;
  font-family: Roboto-Regular, sans-serif;
}
.regenerated-details-container .form-element input:focus {
  outline: none;
  border: 2px solid #ffffff;
}
.regenerated-details-container .form-element textarea {
  min-height: 230px;
  background-color: transparent;
  border: 2px solid #7b7b7b;
  border-radius: 5px;
  color: white;
  font-family: Roboto-Regular, sans-serif;
  font-size: 1.8rem;
  line-height: 3.5rem;
  padding: 0.5em;
  transition: 0.3s;
}
.regenerated-details-container .form-element textarea:focus {
  outline: none;
  border: 2px solid #ffffff;
}
.regenerated-details-container .form-element .regenerated-textarea {
  border-color: #e2ea46;
}
.regenerated-details-container .form-element .regenerated-textarea:focus {
  border-color: #e2ea46;
}
.regenerated-details-container .form-element .regenerated-input {
  border-color: #e2ea46;
}
.regenerated-details-container .form-element .regenerated-input:focus {
  border-color: #e2ea46;
}
.regenerated-details-container .button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
}
.regenerated-details-container .button-container button {
  padding: 1em 2em;
  border: 2px solid #7b7b7b;
  border-radius: 5px;
  background-color: transparent;
  color: #ffffff;
  transition: 0.3s;
  cursor: pointer;
}
.regenerated-details-container .button-container .delete-button:hover {
  border-color: #E82A4D;
  background-color: #4f0f24;
}
.regenerated-details-container .button-container .update-button:hover {
  border-color: #e2ea46;
  background-color: #525425;
}

@media (max-width: 1000px) {
  .regenerated-details-container {
    margin: 0;
    padding: 4em 1.5em 8em 1.5em;
  }
  .regenerated-details-container .form-element input {
    font-size: 1.5rem;
  }
  .regenerated-details-container .form-element textarea {
    font-size: 1.5rem;
  }
  .regenerated-details-container .button-container {
    flex-direction: column-reverse;
    width: 100%;
  }
  .regenerated-details-container .button-container button {
    width: 100%;
  }
}

