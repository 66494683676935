$white: #ffffff;
$black: #111111;
$mint: #1ED88A;
$mintBg: #1ED6894C;
$blackBorder: rgba(17, 17, 17, 0.6);
//$bg-color: #060e0e;
$bg-color: #050505;
//$bg-colorSecond: #1a1f1f;

$bg-colorSecond: #161617;
$red: #E82A4D;
$redBg: #D900424C;
$purple: #5120ff;
$purpleBg: #5120FF4C;
$orange: #F29220;
$orangeBg: #FF9C004C;
$blue: #07C3FF;
$blueBg: rgba(16, 187, 223, 0.3);
$yellow: #E2EA46FF;
$yellowBg: #E0E8454C;
$textColor: #d9e2e6;
$grayColor: #7b7b7b;
$hoverTextColor: rgb(35, 35, 35);
$blueToast: #144652;
$yellowToast: #525425;
$redToast: #4f0f24;
$mintToast: #184e39;
$purpleToast: #27195b;