@import "colors";
nav {
  padding: 2em 4em;
  z-index: 2;
  position: sticky;
  margin-left: 220px;

  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .hamburger {
      display: none;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 1em;

      .hamburger-bar {
        width: 40px;
        height: 2px;
        background-color: $mint;
      }
    }
    h2 {
      font-weight: normal;
      color: $white;
      font-family: Raleway-Regular, sans-serif;
      span {
        color: $mint;
      }
    }

    .navbar-search {
      border-bottom: 2px solid #7b7b7b;
      transition: 0.3s;
      display: flex;
      justify-content: center;
      align-items: center;

      input {
        border: none;
        background-color: transparent;
        transition: 0.3s;
        color: #7b7b7b;
        font-size: 1.8rem;
        padding: 0.4em;
      }

      input:focus {
        outline: none;
        color: $white;

      }
      &:focus-within {
        border-color: $white;
      }



      .navbar-icons-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2em;
        padding-bottom: 0.5em;
        .search-icon {
          height: 2.5rem;
          width: auto;
          color: $white;
          cursor: pointer;
          padding-right: 1em;
        }

        .pencil-icon {
          width: auto;
          color: $white;
          cursor: pointer;
          height: 2rem;
        }
        .separator {
          width: 1px;
          height: 2rem;
          background-color: $white;
        }
      }
    }
  }

}
@media (max-width: 1000px) {

  nav {
    margin: 0;
    padding: 1em 1.5em;

    .navbar-container {
      h2 {
        display: none;
      }

      .hamburger {
        display: flex;
      }
    }
  }
}
