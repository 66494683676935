@import "colors";

.publicationDetails-container {
  margin-left: 220px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 6em 4em;

  color: white;

  .stats-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4em;
    width: 100%;

    .stats-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background-color: $mint;
      padding: 1em 4em 1em 1em;
      border-radius: 5px;
      gap: 1em;
      min-width: 140px;

      .stats-heading {
        margin: 0;
        font-family: Raleway-Medium, sans-serif;
        font-size: 1.2rem;

      }

      .stats-value {
        margin: 0;
        font-family: Roboto-Bold, sans-serif;
        font-size: 2.5rem;
      }
    }
    
    .stats-wrapper-mint {
      background-color: #215941;
      border: 2px solid $mint;
    }
    .stats-wrapper-standard {
      background-color: #215941;
      border: 2px solid $mint;
    }
    .stats-wrapper-important{
      border: 2px solid $yellow;
      background-color: $yellowBg;
    }
    .stats-wrapper-urgent{
      border: 2px solid #f65606;
      background-color: rgba(244, 85, 6, 0.3);
    }
  }

  form {
    width: 100%;
    padding-top: 4em;
    .form-firstPart-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      .form-firstPart-part {
        width: 50%;
      }
      .left {
        padding-left: 1.5em;
        display: flex;
        flex-direction: column;
        gap: 2em;
      }
      .right {
        padding-right: 1.5em;
        display: flex;
        flex-direction: column;
        gap: 2em;
      }
    }
  }
}

.form-input-wrapper {
  display: flex;
  flex-direction: column;

  label {
    color: $white;
    font-size: 1.4rem;
    font-family: Raleway-Regular, sans-serif;
    padding: 0 0 0.3em 0.2em;
  }

  input {
    background-color: transparent;
    border: 2px solid $grayColor;
    border-radius: 5px;
    padding: 0.5em;
    color: $white;
    transition: 0.3s;
    font-size: 1.8rem;
    font-family: Roboto-Regular, sans-serif;
  }

  input:focus {
    outline: none;
    border: 2px solid $white;
  }
}
.fieldset-container {
  display: flex;
  flex-direction: column;
  //gap: 0.2em;

  fieldset {
    border: 2px solid $grayColor;
    border-radius: 5px;
    margin: 0;
    display: flex;
    flex-direction: column;
    padding: 2em 4em 2em 1em;
    gap: 2.45em;

    label {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      cursor: pointer;

      .category-text {
        font-family: Roboto-Regular, sans-serif;
        font-size: 1.8rem;
        transition: 0.3s;
      }

      input[type="radio"] {
        display: none;
        accent-color: $mint;
        transition: 0.3s;
        margin: 0;
        width: 30px;
      }
      .radio-mark {
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 2px solid $white;
        border-radius: 50%;
        background-color: $grayColor;
        position: relative;
        cursor: pointer;
        transition: 0.3s;
      }
      input[type="radio"]:checked + .business {
        background-color: $purpleBg;
        border-color: $purple;
      }

      input[type="radio"]:checked + .sport {
        background-color: $blueBg;
        border-color: $blue;
      }

      input[type="radio"]:checked + .politics {
        background-color: $orangeBg;
        border-color: $orange;
      }

      input[type="radio"]:checked + .news {
        background-color: $redBg;
        border-color: $red;
      }

      input[type="radio"]:checked + .mint {
        background-color: $mintBg;
        border-color: $mint;
      }
      input[type="radio"]:checked + .radio-mark.news + .category-text {
        color: $red;
      }

      input[type="radio"]:checked + .radio-mark.politics + .category-text {
        color: $orange;
      }

      input[type="radio"]:checked + .radio-mark.sport + .category-text {
        color: $blue;
      }

      input[type="radio"]:checked + .radio-mark.business + .category-text {
        color: $purple;
      }

      input[type="radio"]:checked + .radio-mark.mint+ .category-text {
        color: $mint;
      }
    }
  }
}

.fieldset-label {
  color: $white;
  font-size: 1.4rem;
  font-family: Raleway-Regular, sans-serif;
  padding: 0 0 0.3em 0.2em;
}

.summary-container {
  padding-top: 2em;
  textarea {
    min-height: 230px;
    background-color: transparent;
    border: 2px solid $grayColor;
    border-radius: 5px;
    color: white;
    font-family: Roboto-Regular, sans-serif;
    font-size: 1.8rem;
    line-height: 3.5rem;
    padding: 0.5em;
  }
}

.form-button-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 3em;
  gap: 3em;

  button {
    padding: 1em 2em;
    border: 2px solid $grayColor;
    border-radius: 5px;
    background-color: transparent;
    color: $white;
    transition: 0.3s;
    cursor: pointer;
  }

  button:hover {
    background-color: $mintBg;
    border-color: $mint;
  }

  .regenerate:hover {
    border-color: $yellow;
    background-color: $yellowBg;
  }
  .update:hover {
    border-color: $blue;
    background-color: $blueBg;
  }
}

.delete-button-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 3em;

  button {
    padding: 1em 2em;
    border: 2px solid $red;
    border-radius: 5px;
    background-color: transparent;
    color: $white;
    transition: 0.3s;
    cursor: pointer;
  }

  button:hover {
    background-color: $redBg;
  }
}

.schedule-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 3em;

  label {
    color: $white;
    font-size: 1.4rem;
    font-family: Raleway-Regular, sans-serif;
    padding: 0 0 0.3em 0.2em;
  }

  .schedule-wrapper {
    display: flex;
    gap: 2em;
    justify-content: center;
    align-items: center;
  }
  input {
    background-color: transparent;
    border: 2px solid $grayColor;
    border-radius: 5px;
    padding: 0.5em;
    color: $white;
    transition: 0.3s;
    font-size: 1.8rem;
    font-family: Roboto-Regular, sans-serif;
  }

  input:focus {
    outline: none;
    border: 2px solid $white;
  }

  button {
    padding: 1em 2em;
    border: 2px solid $grayColor;
    border-radius: 5px;
    background-color: transparent;
    color: $white;
    transition: 0.3s;
    cursor: pointer;
  }

  button:hover {
    background-color: $purpleToast;
    border-color: $purple;
  }
}


@media (max-width: 1000px) {
  .publicationDetails-container {
    margin: 0;
    padding: 4em 1.5em 8em 1.5em;

    .stats-container {
      gap: 0;
      justify-content: space-between;
      .stats-wrapper {
        padding: 1em 2em 1em 1em;

        .stats-value {
          font-size: 1.8rem;
        }
      }
    }
    form {
      padding-top: 3em;

      .form-firstPart-wrapper {
        flex-direction: column;
        gap: 3em;

        .form-firstPart-part {
          width: 100%;
          padding: 0;
          margin: 0;
        }
      }
    }

  }

  .form-input-wrapper {

    input {
      font-size: 1.5rem;
    }
  }
  .fieldset-container {
    fieldset {
      padding: 2em;
    }
  }

  .summary-container {
    textarea {
      font-size: 1.5rem;
      line-height: 3rem;
      min-height: 400px;
    }
  }

  .form-button-container {
    flex-direction: column-reverse;

    button {
      width: 100%;
    }
  }

  .delete-button-container {
    display: flex;
    width: 100%;
    button {
      width: 100%;
    }
  }
}